import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import Loop from "../Loop";
import SectionHeader from '../SectionHeader'

const LatestSermons = ( {block}  ) => {

    const classNames = 'container mx-auto py-24 ' + block.attributes.className;

    const data = useStaticQuery(graphql`
        query latest_sermons {
            allWpSermon(
                sort: { fields: date, order: DESC },
                limit: 4
            ) {
                nodes {
                    uri
                    title
                    excerpt
                    date
                    nodeType
                    featuredImage {
                        node {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                            }
                        }
                    }
                    sermonMeta {
                        displayDate
                    }
                }
            }         
        }
	`);

    return (
       <div className={classNames}>
            <SectionHeader
                label={block.attributes.label}
                link="/resources/?category=sermon"
                linkLabel="See All"
            />
           <Loop
                items={data.allWpSermon.nodes}
            />
       </div>
    )
}

export default LatestSermons;