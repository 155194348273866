import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../Button';

const FeaturedBooks = ({label}) => {
   
    const data = useStaticQuery(graphql`
		query FeaturedBooks {
            allWpBook(
                limit: 6
                sort: {fields: date, order: DESC}
            ) {
				nodes {
                    title
                    uri
                    date
                    featuredImage {
                        node {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData( height: 230, quality: 100)
                                }
                            }
                            mediaDetails {
                            width
                            height
                            }
                        }
                    }
                }
			}
        }
	`);

    return (
        <div className="bg-gold-100 py-8 md:pt-16 md:pb-4 lg:pt-24 lg:pb-8 text-center">
            <h2 className="text-sm font-sans px-6 xl:px-0 font-semibold tracking-widest uppercase mb-2 mt-0 flex-1">
                {label}
            </h2>
            <div className="container mx-auto flex flex-wrap mt-20">
                {data.allWpBook.nodes.map((book, i) => {
                    const imageData = getImage(book.featuredImage?.node?.localFile);

                    return (
                        <Link
                            to={book.uri}
                            className="px-4 py-4 md:py-0 w-full md:w-1/3 lg:w-1/6"
                            >
                            
                            <GatsbyImage
                                image={imageData} 
                                className="shadow-lg" 
                                width="336"
                            />
                            <p className="mt-8">{book.title}</p>

                        </Link>
                    )
                })}
            </div>
            
            <div className="mt-16">
                <Button
                    link="/resources/?category=book"
                    text="All Books"
                />
            </div>
            
        </div>
    )
}

export default FeaturedBooks