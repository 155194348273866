import Latest from './Latest'
import LatestPosts from './LatestPosts'
import LatestSermons from './LatestSermons'
import ButtonsBlock from './ButtonsBlock'
import Embed from './Embed'
import PullQuote from './PullQuote'
import Group from './Group'
import Columns from './Columns'
import Column from './Column'
import PodcastEpisodes from './PodcastEpisodes'
import UpcomingEvents from './UpcomingEvents'
import FeaturedBooks from './FeaturedBooks'
import ShortBio from './ShortBio'
import WhereToFind from './WhereToFind'

export default {
    Latest,
    LatestPosts,
    LatestSermons,
    PullQuote,
    Column,
    Columns,
    Group,
    PodcastEpisodes,
    UpcomingEvents,
    FeaturedBooks,
    ShortBio,
    WhereToFind,
    ButtonsBlock,
    Embed
}