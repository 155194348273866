import React from "react"
import SounderEmbed from "./Embeds/SounderEmbed"
import SpotifyEmbed from "./Embeds/SpotifyEmbed"
import VimeoEmbed from "./Embeds/VimeoEmbed"
import YouTubeEmbed from "./Embeds/YouTubeEmbed"

const Embed = ({ block }) => {
  let isSounder = false;
  let isVimeo   = false;
  let isYouTube = false;
  let isSpotify = false;
  let isApple   = false;
  let isPodBean = false;

  const embedURL = block?.attributes?.url

  if (embedURL) {
    isSounder = embedURL.includes("sounder");
    isVimeo   = block?.attributes.providerNameSlug == 'vimeo';
    isYouTube = block?.attributes.providerNameSlug == 'youtube';
    isSpotify = embedURL.includes("open.spotify");
    isApple   = embedURL.includes("embed.podcasts.apple.com");
    isPodBean = embedURL.includes("podbean.com");
  }

  return (
    <>
      {isSounder && (
        <SounderEmbed
          url={embedURL}
        />
      )}

      {isVimeo && (
        <VimeoEmbed
          url={embedURL}
        />
      )}

      {isYouTube && (
        <div className="my-6">
          <YouTubeEmbed
            url={embedURL}
            classNames="absolute top-0 left-0 w-full h-full"
          />
        </div>
      )}

      {isSpotify && (
        <div className="my-6">
          <SpotifyEmbed
            url={embedURL}
            classNames="absolute top-0 left-0 w-full h-full"
          />
        </div>
      )}

      {isApple && (
        <div className="my-6">
          <iframe
            src={embedURL}
            height="200"
            width="100%"
          />
        </div>
      )}

      {isPodBean && (
        <div className="my-6">
          <iframe
            src={embedURL}
            height="650"
            width="100%"
          />
        </div>
      )}

      {!isSounder && !isVimeo && !isYouTube && !isSpotify && !isApple && !isPodBean && (
        <iframe
          src={embedURL}
          width="100%"
          height="1640px"
        />
      )}
    </>
  )
}

export default Embed
