import { useStaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import SectionHeader from '../SectionHeader'

const PodcastEpisodes = ({block}) => {

    const data = useStaticQuery(graphql`
		query PodcastEpisodes {
            allWpPodcastEpisode ( 
                limit: 5
                sort: { fields: date, order: DESC }
            ) {
				nodes {
                    title
                    uri
                    date
                    podcastMeta {
                        podcastLength
                    }
                    resourceMeta {
                        displayDate
                    }
                }
			}
        }
	`);

    return (
        <div className="flex-1">
            <SectionHeader
                label={block.attributes.label}
                link="/resources/?category=podcast_episode"
                linkLabel="See All"
           />

            {data.allWpPodcastEpisode.nodes.map((episode, i) => {

                const displayDay = new Date(episode.date);

                return (
                    <Link
                        to={episode.uri}
                        className="latest-podcasts flex items-center pt-6 pb-8 border-b border-b-gray-300"
                        >
                        <div className="bg-gold-100 rounded-2xl w-32 h-24 flex justify-center items-center flex-col mr-8">
                            <span className="text-3xl text-gold-700">{ new Intl.DateTimeFormat( 'en-US', { day: 'numeric' }).format(displayDay) }</span>
                            <span className="text-gray-600 uppercase">{ new Intl.DateTimeFormat( 'en-US', { month: 'short' }).format(displayDay) }</span>
                        </div>
                        <div className="w-full">
                            <h3 className="mb-4 pt-2 mt-0">{episode.title}</h3>
                            <span className="text-gray-600">{episode.podcastMeta.podcastLength}</span>
                        </div>
                    </Link>
                )
            })}
        </div>
    )
}

export default PodcastEpisodes