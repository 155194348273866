import React from 'react'

const VimeoEmbed = ( {url} ) => {

  let urlObject = new URL(url);
  let videoID   = urlObject.pathname.replace(/^(\/)+/, '');

  if ( ! videoID )
    return null

  return (
      <div className="my-6">
          
      </div>
  )
}

export default VimeoEmbed