import React from 'react'
import Spotify from 'react-spotify-embed'

const SpotifyEmbed = ( {url} ) => {

  return (
      <Spotify link={url} width="100%" />
  )
}

export default SpotifyEmbed