import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react';
import Button from '../Button';

const ShortBio = ({block}) => {

    return (
    
        <div className="bio relative min-h-96 flex flex-col-reverse xl:block">
            <div class="bg-white xl:absolute w-full xl:w-1/2 xl:w-1/3 px-8 xl:px-16 2xl:px-20 py-12 lg:py-20 xl:py-24 z-10 top-10 md:top-20 lg:top-28 right-12 md:right-20 lg:right-32">
                <h2 className="">
                    {block.attributes.name}
                </h2>
                <p dangerouslySetInnerHTML={ {__html: block.attributes.shortBio}  } />
                <Button
                    link={block.attributes.buttonLink}
                    text={block.attributes.buttonLabel}
                    position="left"
                />
            </div>

            <StaticImage src='../../assets/images/kdy-bio-bg.jpg' layout="full-width" className="relative object-fill" alt="" />

        </div>
    )
}

export default ShortBio