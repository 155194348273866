import { useStaticQuery, graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Button from '../Button';
import Loop from "../Loop";
import SectionHeader from '../SectionHeader'

const Latest = ( {block}  ) => {

    const classNames = 'container mx-auto py-8 md:pb-4 lg:pb-8 ' + block.attributes.className;
    const [stickyPost, setStickyPost] = useState([]);

    useEffect(() => {
        const getStickyPost = async () => {
            const stickyPosts = await fetch( 'https://kdyprod.wpengine.com/wp-json/wp/v2/posts/?sticky=true');
            setStickyPost(await stickyPosts.json());
        };

        getStickyPost();
    }, []);

    const data = useStaticQuery(graphql`
        query latest {
            allWpContentNode(
                filter: { nodeType: { regex: "/Lecture|Discussion|PodcastEpisode|SermonClip|Conference|Post/" } },
                sort: { fields: date, order: DESC },
                limit: 3
            ) {
                nodes {
                    nodeType
                    ... on WpPost {
                        uri
                        title
                        excerpt
                        date
                        featuredImage {
                            node {
                              altText
                              localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                              }
                            }
                        }
                        resourceMeta {
                            displayDate
                        }
                    }
                    ... on WpSermon {
                        uri
                        title
                        excerpt
                        date
                        featuredImage {
                            node {
                              altText
                              localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                              }
                            }
                        }
                        sermonMeta {
                            displayDate
                        }
                    }
                    ... on WpSermonClip {
                        uri
                        title
                        excerpt
                        date
                        featuredImage {
                            node {
                              altText
                              localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                              }
                            }
                        }
                    }
                    ... on WpLecture{
                        uri
                        title
                        excerpt
                        date
                        featuredImage {
                            node {
                              altText
                              localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                              }
                            }
                        }
                        resourceMeta {
                            displayDate
                        }
                    }
                    ... on WpPodcastEpisode {
                        uri
                        title
                        excerpt
                        date
                        featuredImage {
                            node {
                              altText
                              localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                              }
                            }
                        }
                        resourceMeta {
                            displayDate
                        }
                    }
                    ... on WpConference {
                        uri
                        title
                        excerpt
                        date
                        featuredImage {
                            node {
                              altText
                              localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                              }
                            }
                        }
                        resourceMeta {
                            displayDate
                        }
                    }
                    ... on WpDiscussion {
                        uri
                        title
                        excerpt
                        date
                        featuredImage {
                            node {
                              altText
                              localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 767, height: 400, quality: 90)
                                }
                              }
                            }
                        }
                    }
                }
            }
        }
	`);
    
    return (
        <div className={classNames}>
             <SectionHeader
                 label={block.attributes.label}
             />
                <Loop
                    stickyItem={stickyPost}
                    items={data.allWpContentNode.nodes}
                />
             
 
             <div className="mt-16">
                 <Button
                     link="/resources/"
                     text="All Resources"
                 />
             </div>
        </div>
    )
}

export default Latest;