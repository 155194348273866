import React from 'react'

const SounderEmbed = ( {url} ) => {

    return (
        <div
            className="sounder-widget mb-8"
            data-url={url}
            style={{ width: "100%", height: "100%" }}
          >
            <iframe
              id="sounder-player"
              src={`${url}`}
              class="sounder-fm-embed sounder-fm-embed-el"
              width="100%"
              height="188px"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameborder="0"
              allowfullscreen=""
              webkitallowfullscreen=""
              mozallowfullscreen=""
              style={{
                transition: "all 0.35s ease 0s",
                boxSizing: "border-box",
              }}
            ></iframe>
        </div>
    )
}

export default SounderEmbed