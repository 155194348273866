import React from 'react'
import ComponentParser from '../../utils/ComponentParser'

const Columns = ({block}) => {
    console.log(block?.attributes?.className);
    return (
        <div className={`${block?.attributes?.className} container mx-auto md:flex md:gap-20 py-8 md:py-16 lg:py-24`}>
                {block.innerBlocks &&
                block.innerBlocks.map((innerBlock, i) => {
                    return (
                        <ComponentParser
                            block={innerBlock}
                            key={i}
                        />
                    )
                })}
                <div className="!py-0 lg:py-0"></div>
        </div>
    )
}

export default Columns