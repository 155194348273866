import React from 'react'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'


const PullQuote = ( {block, page} ) => {
    
    const uri = page?.uri;
    const url = 'https://clearlyreformed.org' + uri;

    const quote = block?.attributes?.value.replace(/(<([^>]+)>)/gi, "");

    if ( ! quote ) return null;

    return (
        <div className="pullquote bg-gold-100 w-full p-4 md:p-10 rounded-2xl md:float-right flex flex-col">
            <div dangerouslySetInnerHTML={ {__html: block.attributes.value}  } />
            <div className="flex justify-end -mt-4">
                <FacebookShareButton quote={quote} url={url}><FacebookIcon size="40" bgStyle={{ fill: "none" }} iconFillColor="#1F2828" /></FacebookShareButton>
                <TwitterShareButton title={quote} url={url}><TwitterIcon size="40" bgStyle={{ fill: "none" }} iconFillColor="#1F2828" /></TwitterShareButton>
            </div>
        </div>
    )
}

export default PullQuote