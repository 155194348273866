import React from 'react'
import ComponentParser from '../../utils/ComponentParser'

const Column = ( {block} ) => {
	const classNames=`column lg:my-24 lg:my-0 lg:flex-1 px-4 md:px-0 xl:px-12 ${block.attributes?.className}`;
	const innerClassName = block.attributes?.className.includes('sticky-content') ? 'sticky top-4' : '';
   
	return (
        <div className={classNames}>
			<div className={innerClassName}>
            {block.innerBlocks &&
      		block.innerBlocks.map((innerBlock, i) => {
				return (
					<ComponentParser
						block={innerBlock}
						key={i}
					/>
				)
			})}
			</div>
		</div>
    )
}

export default Column