import React from 'react'
import Button from '../Button';

const ButtonsBlock = ( {block} ) => {
    return (
        <Button
            link={block.innerBlocks[0].attributes.url}
            target={block.innerBlocks[0].attributes.linkTarget}
            text={block.innerBlocks[0].attributes.text}
            style={block.innerBlocks[0].attributes.className}
        />
    )
}

export default ButtonsBlock