import React from 'react';
import Blocks from '../components/Blocks';

const ComponentParser = ( { block, page } ) => {
	switch (block.name) {
		case 'kdy/latest':
			return Blocks.Latest({block});

		case 'kdy/latest-posts':
			return Blocks.LatestPosts({block});
		
		case 'kdy/latest-sermons':
			return Blocks.LatestSermons({block});
			
		case 'kdy/podcast-episodes':
			return Blocks.PodcastEpisodes({block});

		case 'kdy/upcoming-events':
			return Blocks.UpcomingEvents({block});

		case 'kdy/featured-books':
			const label = block.attributes.label;
			return Blocks.FeaturedBooks({label});
		
		case 'kdy/bio':
			return Blocks.ShortBio({block});

		case 'kdy/where':
			return Blocks.WhereToFind({block});

		case 'core/pullquote':
			return Blocks.PullQuote({block, page});
		
		case 'core/buttons':
			return Blocks.ButtonsBlock({block});

		case 'core/columns':
			return Blocks.Columns({block});
		
		case 'core/column':
			return Blocks.Column({block});

		case 'core/group':
			return Blocks.Group({block});
		
		case 'core/embed':
			return Blocks.Embed({block});
				
		default:
			return <div dangerouslySetInnerHTML={ {__html: block.saveContent}  } />
	}
}

export default ComponentParser