import React from 'react';
import SectionHeader from '../SectionHeader';
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const UpcomingEvents = ({block}) => {

    const data = useStaticQuery(graphql`
		query UpcomingEvents {
            allWpEvent (
                limit: 4,
                filter: {eventMeta: {eventHasPassed: {ne: true}}}
                sort: {order: ASC, fields: eventMeta___eventStartDate}
            ) {
				nodes {
                    title
                    uri
                    date
                    eventMeta {
                        eventDateDisplay
                        eventLocation
                        eventStartDate
                    }
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, height: 350, quality: 90 )
                                }
                            }
                        }
                    }
                }
			}
        }
	`);

    const imageData = getImage(data?.allWpEvent?.nodes[0]?.featuredImage?.node?.localFile);

    return (
        <div>
            <SectionHeader
                label={block.attributes.label}
                link="/events/"
                linkLabel="See all"
           />
           {data.allWpEvent.nodes.map((event, i) => {

                const displayDay = new Date(event.eventMeta.eventStartDate);

                return (
                     i == 0 ?
                        <Link
                            to={event.uri}
                            className="upcoming-events pt-6 pb-8 block"
                            index={i}
                            >
                            
                            <GatsbyImage image={imageData} className="rounded-lg w-full" width="835" />

                            <div className="flex items-center mt-8">
                                <div className="bg-gold-100 rounded-2xl w-32 h-24 flex justify-center items-center flex-col mr-8">
                                    <span className="text-3xl text-gold-700">{ new Intl.DateTimeFormat( 'en-US', { day: 'numeric' }).format(displayDay) }</span>
                                    <span className="text-gray-600">{ new Intl.DateTimeFormat( 'en-US', { month: 'short' }).format(displayDay) }</span>
                                </div>
                                <div className="w-full">
                                    <h3 className="mb-4 pt-2 mt-0">{event.title}</h3>
                                    <span className="text-gray-600">{event.eventMeta.eventLocation}</span>
                                </div>
                            </div>
                        </Link> :
                        <Link
                            to={event.uri}
                            className="upcoming-events block pt-6 pb-8 border-t border-t-gray-300 w-full"
                            index={i}
                            >
                            <div className="flex flex-row items-center">
                                <span className="basis-12 text-gold-700 text-sm">{ new Intl.DateTimeFormat( 'en-US', { day: 'numeric' }).format(displayDay) } </span>
                                <span className="basis-12 text-gray-600 text-sm uppercase">{ new Intl.DateTimeFormat( 'en-US', { month: 'short' }).format(displayDay) }</span>
                                <h3 className="basis-2/3 text-base ml-10 mt-0 mb-0">{event.title}</h3>
                                <span className="basis-2/3 text-gray-600 text-sm text-right">{event.eventMeta.eventLocation}</span>
                            </div>
                        </Link>
                )
           })}
        </div>
    )
}

export default UpcomingEvents