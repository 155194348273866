import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const WhereToFind = ({block}) => {

    return (
        <div className="lg:w-[130%] lg:-ml-[15%]">
            <h2>{block.attributes.label}</h2>
            <div className="md:flex flex-wrap mt-12">

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://twitter.com/RevKevDeYoung"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/twitter.png" width={60} quality="100" />
                        Kevin's Twitter
                    </Link>
                </div>
                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://www.youtube.com/channel/UCJBM4tCtuXoqpV5y64cWPYQ"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/youtube.png" width={60} quality="100" />
                        YouTube
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://www.facebook.com/RevKevDeYoung/"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/facebook.png" width={60} quality="100" />
                        Kevin's Facebook
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://podcasts.apple.com/us/podcast/life-and-books-and-everything/id1526483896/"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/podcast.png" width={60} quality="100" />
                        <div>
                            Life &amp; Books &amp; Everything
                            <div className="text-xs uppercase font-sans text-gray-400">Podcast</div>
                        </div>
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://www.crossway.org/authors/kevin-deyoung/"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/crossway.png" width={60} quality="100" />
                        <div>
                            Crossway
                            <div className="text-xs uppercase font-sans text-gray-400">Author</div>
                        </div>
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://wng.org/authors/kevin-deyoung"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/world.png" width={60} quality="100" />
                        <div>
                            World Magazine
                            <div className="text-xs uppercase font-sans text-gray-400">Contributor</div>
                        </div>
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://www.9marks.org/by-author/kevin-deyoung/"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/9marks.png" width={60} quality="100" />
                        <div>
                            9Marks
                            <div className="text-xs uppercase font-sans text-gray-400">Contributor</div>
                        </div>
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://www.ligonier.org/learn/teachers/kevin-deyoung/"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/ligonier.png" width={60} quality="100" />
                        <div>
                            Ligonier
                            <div className="text-xs uppercase font-sans text-gray-400">Contributor</div>
                        </div>
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://www.desiringgod.org/authors/kevin-deyoung/"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/desiring-god.png" width={60} quality="100" />
                        <div>
                            Desiring God
                            <div className="text-xs uppercase font-sans text-gray-400">Contributor</div>
                        </div>
                    </Link>
                </div>

                <div className="location w-full md:w-1/2 mb-12">
                    <Link
                        to="https://www.thegospelcoalition.org/blogs/kevin-deyoung/"
                        className="flex items-center gap-4 font-serif text-xl text-black hover:text-green-600"
                        target="_blank"
                    >
                        <StaticImage src="../../assets/images/organizations/tgc.png" width={60} quality="100" />
                        <div>
                            The Gospel Coalition
                            <div className="text-xs uppercase font-sans text-gray-400">Contributor</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default WhereToFind;